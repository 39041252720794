import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundColor: "#fff100",
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <img src="lidl.png" style={{ marginTop: "30vh" }} />
    </div>
  );
}

export default App;
